<template>
  <div>
    <div class="team-management-wrap">
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="teamInfo.length > 10 ? '没有更多了': ''"
          :immediate-check="false"
          @load="onLoad"
      >
        <div class="team-management-cell-box">
          <van-swipe-cell class="team-management-cell"  v-for="(item, index) in teamInfo" :key="index">
            <div class="team-management-item" @click="editLink(item, index)">
              <div class="management-item-header vertical-center">
                <p class="title white-space">{{ item.name }}</p>
                <div class="number vertical-center">
                  <!--                <img src="~assets/images/user/team-people-icon.png" alt="">-->
                  <p>({{ $bigNumberTransform.bigNumberTransform(item.member_number) }})</p>
                </div>
              </div>
              <div class="management-item-user">
                <div class="vertical-center">
                  <p class="team-tit" v-if="index != 0">小队长：</p>
                  <div v-if="item.wechat">
                    <img :src="item.wechat.avatar && item.wechat.avatar != '' ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" class="user-avatar"
                         alt="">
                  </div>
                  <p class="user-name white-space">{{ item.wechat ? item.wechat.nickname : '无' }}</p>
                </div>
              </div>
              <img src="~assets/images/user/team-list-edit-icon.png" class="edit-icon"  alt="">
            </div>
            <template #right v-if="index != 0">
              <div class="team-del align-center" @click.stop="delTeam(item, index)">
                <img src="~assets/images/user/team-del-icon.png" alt="">
              </div>
            </template>
          </van-swipe-cell>
        </div>

      </van-list>
      <no-data v-if="teamInfo.length == 0 && list_status"></no-data>
      <div class="bottom-btn-height safety-height"></div>
      <!--底部按钮-->
      <div class="team-bottom safety-height">
        <div class="bottom-btn" @click="createdTeam">创建小队</div>
      </div>
    </div>

    <!--创建小队弹框开始-->
    <!--    <van-popup round :close-on-click-overlay="false" v-model:show="create_team_status">
      <div class="create-team-pop">
        <img src="~assets/images/index/close-icon.png" class="close-icon" @click="create_team_status= false" alt="">
        <div class="create-team-item vertical-center">
          <p class="item-l"><span class="inp-hint-icon">*</span>小队名称</p>
          <div class="item-r">
            <input type="text" maxlength="15" v-model="name" placeholder="请输入小队名称" class="item-inp">
          </div>
        </div>
        <div class="create-team-item vertical-start">
          <p class="item-l">头像</p>
          <div class="item-r upload-box vertical-start">
            &lt;!&ndash;上传&ndash;&gt;
            <div class="upload-cont">
              <div class="vant-upload-box">
                <van-uploader v-model="fileList" :max-count="1" :after-read="afterRead" :max-size="10240 * 1024"
                              @oversize="onOversize"
                              :before-delete="deleteImg"/>
              </div>
              <p class="hint-p">上传小队封面图</p>
            </div>
            <div class="example-box">
              <img src="~assets/images/user/st-example.png" class="example-img" alt="">
              <p>示例图</p>
            </div>
          </div>
        </div>
        <div class="create-team-item vertical-center">
          <p class="item-l"><span class="inp-hint-icon">*</span>小队长</p>
          <div class="item-r">
            <input type="text" maxlength="11" v-model="mobile" placeholder="请输入小队长手机号" class="item-inp">
          </div>
        </div>
        <div class="btn-box align-center">
          <div class="btn cancel-btn" @click="create_team_status= false">取消</div>
          <div class="btn confirm-btn" @click="teamSubmit">确定</div>
        </div>
      </div>
    </van-popup>-->
    <!--创建小队弹框结束-->

    <!-- 加载提示   -->
    <van-overlay :show="login_loading_show" z-index="9999">
      <div class="lodding-wrap">
        <van-loading type="spinner"/>
      </div>
    </van-overlay>
  </div>

</template>

<script>
import NoData from "@/components/NoData";
import _ from "lodash"

export default {
  name: "Index",
  data() {
    return {
      loading: false,
      finished: false,
      pageSize: 10,
      page: 1,
      create_team_status: false,
      teamInfo: [], // 小队数据
      fileList: [], // 上传数据
      name: '', //  小队名称
      mobile: '', // 小队长手机号
      cover_picture: '', // 小队头像
      list_status: false,
      noGroup: {},
      login_loading_show: false,
      uploaderTimeout: null
    }
  },
  created() {
    if (this.userInfo && this.userInfo.is_team_master != 1) {
      this.$router.replace('/user')
    }
    // 获取未分组
    this.getNoGroup()
  },
  methods: {
    // 获取小队管理列表
    getTeam() {
      let url = this.$api.TeamGroups + '?page=' + this.page + '&type=0'
      this.list_status = false
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          let _data = res.data.data.data
          this.page++
          if (!_data.length) {
            this.finished = true;
          }
          this.loading = false;
          this.teamInfo = this.teamInfo.concat(_data)
          setTimeout(() => {
            this.list_status = true
          }, 300)
        }
      })
    },
    // 获取未分组小队列表
    getNoGroup() {
      let url = this.$api.TeamGroups + '?page=' + this.page + '&type=1'
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.noGroup = res.data.data
          this.teamInfo.unshift(this.noGroup)
          // 获取小队列表信息
          this.getTeam()
        }
      })
    },
    // 获取消费默认值
    teamDefault() {
      this.page = 1
      this.teamInfo = []
      this.loading = false
      this.finished = false
      this.list_status = false
    },
    // 小队创建提交
    teamSubmit: _.debounce(function () {
      if (this.name.trim() == '') {
        this.$toast('请输入小队名称')
        return false
      }
      if(this.mobile.trim() == ''){
        this.$toast('请输入小队长手机号')
        return false
      }
      if (this.mobile.trim().length != 11) {
        this.$toast('请输入正确手机号格式')
        return false
      }
      // 判断是否上传图片
      if (this.fileList.length > 0) {
        if (this.cover_picture != '') {
          this.teamData()
        } else {
          this.uploaderFun()
        }
        this.login_loading_show = true
      } else {
        this.login_loading_show = true
        this.teamData()
      }
    }, 500),
    // 提交数据
    teamData() {
      let url = this.$api.TeamGroups
      let data = {
        name: this.name,
        mobile: this.mobile.trim(),
        cover_picture: this.cover_picture
      }
      this.$http.post(url, data, true).then(res => {
        if (res.data.success) {
          this.teamDefault()
          this.getNoGroup()
          this.login_loading_show = false
          this.$toast('设置成功')
          setTimeout(() => {
            this.create_team_status = false;
            this.createCancel()
          }, 1500)
        } else {
          this.login_loading_show = false
          this.$toast(res.data.message)
        }
      }).catch(error => {
        this.login_loading_show = false
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
    },
    // 图片上传
    uploaderFun() {
      var param = new FormData()
      param.append('file', this.fileList[0].file)
      param.append('type', 'group')
      if (this.uploaderTimeout !== null) {
        clearTimeout(this.uploaderTimeout)
      }
      this.uploaderTimeout = setTimeout(() => {
        this.fileList[0].status = 'failed';
        this.fileList[0].message = '上传失败';
      }, 60000)
      this.$http.post(this.$api.imageUpload, param, true).then(res => {
        if (res.data.success) {
          clearTimeout(this.uploaderTimeout)
          this.fileList = [{
            url: res.data.data.url,
          }];
          this.cover_picture = res.data.data.url;
          this.teamData()
        } else {
          clearTimeout(this.uploaderTimeout);
          this.login_loading_show = false
          this.fileList[0].status = 'failed';
          this.fileList[0].message = '上传失败';
          this.$toast(res.data.message)
        }
      }).catch(error => {
        clearTimeout(this.uploaderTimeout);
        this.login_loading_show = false
        this.fileList[0].status = 'failed';
        this.fileList[0].message = '上传失败';
      })
    },
    // 创建小队上传回复默认
    createCancel() {
      this.name = ''
      this.mobile = ''
      this.cover_picture = ''
      this.fileList = []
    },
    //  删除图片
    deleteImg() {
      this.content = '';
      this.fileList = [];
    },
    afterRead() {
      this.cover_picture = ''
    },
    //  图片限制大小
    onOversize() {
      this.$toast('文件大小不能超过 10MB')
    },
    // 编辑跳转路由
    editLink(item, index) {
      if (index == 0) {
        this.$router.push({path: `/management-team/edit/${item.id}`, query: {type: 'no_group'}})
      } else {
        this.$router.push({path: `/management-team/edit/${item.id}`})
      }
    },
    // 删除战队
    delTeam(item, index) {
      let _this = this;
      this.$dialog.confirm({
        message: '确认是否删除该小队?',
        confirmButtonColor: "#FF6B29"
      }).then(() => {
        _this.delFun(item, index)
        // on confirm
      }).catch(() => {
        // on cancel
      });
    },
    // 删除方法
    delFun(item, index) {
      let url = this.$api.teamGroups + '/' + item.id
      this.$http.del(url, true).then(res => {
        if (res.data.success) {
          this.teamDefault()
          this.getNoGroup()
          this.$toast('删除成功')
        } else {
          this.$toast(res.data.message)
        }
      }).catch(error => {
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
    },
    // 创建小队
    createdTeam() {
      this.$router.push('/management-team/create')
      //this.create_team_status = true
    },

    // 底部加载更多
    onLoad() {
      if (this.list_status && this.teamInfo.length > 8) {
        this.getTeam()
      }
    }
  },
  components: {
    NoData
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#F7F7F7')
  },
  beforeUnmount() {
    document.querySelector('body').setAttribute('style', 'background:#FFFFFF')
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team-management/team-management.scss";

.lodding-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.vant-upload-box > > > .van-uploader__mask-icon {
  font-size: 12px;
}

.vant-upload-box > > > .van-uploader__mask-message {
  font-size: 10px;
}
</style>
<style lang="scss">
$r: 750/16/1rem;
.van-popup--center.van-popup--round {
  border-radius: 20/$r;
}
</style>

